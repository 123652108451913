import Layout from "antd/lib/layout/layout"
import React, { useState } from "react"
import LandingPageHeader from "../components/LandingPage/LandingPageHeader"
import LandingPageFooter from "../components/LandingPage/LandingPageFooter"
import SEO from "../shared/Components/Seo"

import "../shared/styles/policies.less"

const PrivacyPolice = () => {
  const [clickedFreeTrial, setClickedFreeTrial] = useState(false)
  return (
    <>
      <SEO title="Privacy Policy" />
      <Layout>
        <LandingPageHeader
          clickedFreeTrial={clickedFreeTrial}
          setClickedFreeTrial={setClickedFreeTrial}
        />
        <main style={{ marginTop: 106 }}>
          <div className="container">
            <div className="container__header">
              <h1>Privacy Policy</h1>
            </div>
            <div className="container__content">
              <div>
                <p>
                  <strong>Last Updated: February 17, 2022</strong>
                </p>
                <p>
                  This Privacy Policy outlines Our policies and procedures on
                  the collection, use, and disclosure of your information about
                  you when you use our Service and identifies certain rights you
                  may have as a consumer about how we collect, use, or share
                  your Personal Information. Your use of the Service means you
                  have read, and agree to, Our Privacy Policy and terms and
                  conditions, as outlined below.
                </p>
                <p>
                  We care about your privacy. If you have questions about our
                  policies as it may pertain to you, you can contact
                  privacy@elephantcpa.com.
                </p>
                <h2>Interpretation and Definitions</h2>
                <h3>Interpretation</h3>
                <p>
                  The words of which the initial letter is capitalized have
                  meanings defined under the following conditions. The following
                  definitions shall have the same meaning regardless of whether
                  they appear in singular or in plural.
                </p>
                <h3>Definitions</h3>
                <p>For the purposes of this Privacy Policy:</p>
                <ol>
                  <li>
                    <p>
                      <strong>Account</strong> means a unique account created
                      for you to access our Service or parts of our Service.
                    </p>
                    <p>
                      <strong>Company</strong> (referred to as either "the
                      Company", "We", "Us" or "Our" in this Agreement) refers to
                      CPA Soft LLC D/B/A ElephantCPA, Totowa, NJ 07512. For the
                      purpose of the GDPR, the Company is the Data Controller.
                    </p>
                    <p>
                      <strong>Device</strong> means any device used by you that
                      can access the Service, such as a computer, a mobile
                      phone, or a digital tablet.
                    </p>
                    <p>
                      <strong>Personal Information</strong> refers to any
                      information that identifies you, relates to you, or could
                      be used, whether on its own or in combination with other
                      information, to identify you. Types of Personal
                      Information include but are not limited to:
                    </p>
                  </li>
                  <ul>
                    <li>
                      <p>First and last name</p>
                    </li>
                    <li>
                      <p> Address</p>
                    </li>
                    <li>
                      <p>Email address</p>
                    </li>
                    <li>
                      <p>Internet Protocol (IP) address</p>
                    </li>
                    <li>
                      <p>Usernames on third-party services</p>
                    </li>
                    <li>
                      <p>
                        Social Security Number or identification (ID) number
                      </p>
                    </li>
                    <li>
                      <p>Device-specific identifiers or advertising IDs</p>
                    </li>
                    <li>
                      <p>
                        Other signaling or networking information generated
                        about your interaction with our Website or another
                        website or service
                      </p>
                    </li>
                  </ul>
                  <p>
                    <strong>Service</strong> refers to any functionality and/or
                    product that We may make available or provide to you.
                  </p>
                  <p>
                    <strong>Third-party Service</strong> refers to any website
                    or any social network website through which a User can log
                    in or create an account to use the Service.
                  </p>
                  <p>
                    <strong>Usage Data</strong> refers to data the Company
                    collects, whether generated by your use of the Service or
                    any other product the Company makes available to you.
                  </p>
                  <p>
                    <strong>Website</strong> refers to ElephantCPA, accessible
                    from{" "}
                    <a href="https://elephantcpa.com.">
                      https://elephantcpa.com.
                    </a>
                  </p>
                </ol>
                <h2>Collecting and Using Your Personal Information</h2>
                <h3>Types of Data Collected</h3>
                <p>
                  <strong>Personal Information</strong>
                  <br></br>
                  While using Our Service, We may ask you to provide Us with
                  certain personally identifiable information that can be used
                  to contact or identify you. Personally identifiable
                  information may include, but is not limited to:
                </p>
                <ul>
                  <li>
                    <p>Email address</p>
                  </li>
                  <li>
                    <p>First name and last name</p>
                  </li>
                  <li>
                    <p>Phone number</p>
                  </li>
                  <li>
                    <p>Address, City, State, Province, ZIP/Postal Code</p>
                  </li>
                  <li>
                    <p>Social Security number or ID number</p>
                  </li>
                  <li>
                    <p>Usage data</p>
                  </li>
                </ul>
                <p>
                  When you pay for a product and/or a service via bank transfer,
                  We may ask you to provide information to facilitate this
                  transaction and to verify your identity. Such information may
                  include, without limitation:
                </p>
                <ul>
                  <li>
                    <p>Date of birth</p>
                  </li>
                  <li>
                    <p>Passport or National ID card</p>
                  </li>
                  <li>
                    <p> Other information linking you to an address</p>
                  </li>
                </ul>
                <p>
                  <strong>Usage Data</strong>
                  <br></br>
                  Usage Data is collected automatically when using the Service.
                </p>
                <p>
                  Usage Data may include information such as your device's
                  Internet Protocol (IP) address, browser type, browser version,
                  the pages of our Service that you visit, the time and date of
                  your visit, the time spent on those pages, unique device
                  identifiers, including advertising identifiers, and other
                  signal information.
                </p>
                <p>
                  When you access the Service by or through a mobile device, We
                  may collect certain information automatically, including but
                  not limited to, the type of mobile device you use, your mobile
                  device unique ID, the IP address of your mobile device, your
                  mobile operating system, the type of mobile Internet browser
                  you use, and other diagnostic data.
                </p>
                <p>
                  We may also collect information that your browser sends
                  whenever you visit our Service or when you access the Service
                  by or through a mobile device.
                </p>
                <p>
                  <strong>Information from Third-Party Services</strong>
                  <br></br>
                  Usage of certain features of the Service may require you to
                  create an account on a third-party website or Service,
                  including but not limited to:
                </p>
                <ul>
                  <li>
                    <p>Stripe</p>
                  </li>
                  <li>
                    <p>QuickBooks</p>
                  </li>
                  <li>
                    <p>Zoho Sign</p>
                  </li>
                </ul>
                <p>
                  If you decide to register with or otherwise grant us access to
                  a Third-Party Service, We may collect or share your Personal
                  Information that is associated with your Third-Party Service's
                  account, such as your name, your email address, your
                  activities, or other Personal Information associated with that
                  account.
                </p>
                <p>
                  You may also have the option of sharing additional information
                  with the Company through your Third-Party Service's account.
                  If you choose to provide this Personal Information, during
                  registration or otherwise, you are giving the Company
                  permission to use, share, and store it in a manner consistent
                  with this Privacy Policy.
                </p>
                <p>
                  <strong>Tracking Technologies and Cookies</strong>
                  <br></br>
                  We use Cookies and similar tracking technologies to track the
                  activity on Our Service and store certain information.
                  Tracking technologies used are beacons, tags, and scripts to
                  collect and track information and to improve and analyze Our
                  Service.
                </p>
                <p>
                  A cookie is a small file placed on your Device. You can
                  instruct your browser to refuse all cookies or to indicate
                  when a Cookie is being sent. However, if you do not accept
                  cookies, you may not be able to use some parts of our Service.
                  Unless you have adjusted your browser setting so that it will
                  refuse cookies, our Service may use cookies.
                </p>
                <p>
                  Cookies can be "Persistent" or "Session" cookies. Persistent
                  cookies remain on your personal computer or mobile device when
                  you go offline, while session cookies are deleted as soon as
                  you close your web browser.
                </p>
                <ol>
                  <li>
                    <p>
                      <strong>Necessary / Essential Cookies</strong>
                      <br></br>
                      Type: Session Cookies
                      <br />
                      Administered by: Us
                      <br />
                      Purpose: These Cookies are essential to provide you with
                      services available through the Website and to enable you
                      to use some of its features. They help to authenticate
                      users and prevent fraudulent use of user accounts. Without
                      these Cookies, the services that you have asked for cannot
                      be provided, and We only use these Cookies to provide you
                      with those services.
                      <br />
                      <strong>
                        Cookies Policy / Notice Acceptance Cookies
                      </strong>
                      <br />
                      Type: Persistent Cookies
                      <br />
                      Administered by: Us
                      <br />
                      Purpose: These Cookies identify if users have accepted the
                      use of cookies on the Website.
                      <br />
                      <strong>Functionality Cookies</strong>
                      <br />
                      Type: Persistent Cookies
                      <br />
                      Administered by: Us
                      <br />
                      Purpose: These Cookies allow Us to remember choices you
                      make when you use the Website, such as remembering your
                      login details or language preference. The purpose of these
                      Cookies is to provide you with a more personal experience
                      and to avoid you having to re-enter your preferences every
                      time you use the Website.
                      <br />
                      <strong>Tracking and Performance Cookies</strong>
                      <br />
                      Type: Persistent Cookies
                      <br />
                      Administered by: Third-Parties
                      <br />
                      Purpose: These Cookies are used to track information about
                      traffic to the Website and how users use the Website. The
                      information gathered via these Cookies may directly or
                      indirectly identify you as an individual visitor. This is
                      because the information collected is typically linked to a
                      pseudonymous identifier associated with the device you use
                      to access the Website. We may also use these Cookies to
                      test new pages, features, or new functionality of the
                      Website to see how our users react to them.
                    </p>
                  </li>
                </ol>
                <p>Other types of cookies We may use:</p>
                <ol>
                  <li>
                    <p>
                      <strong>Flash cookies or Local Shared Objects. </strong>
                      Certain features of our Service may use local stored
                      objects (or Flash cookies) to collect and store
                      information about your preferences or your activity on Our
                      Service. Flash cookies are not managed by the same browser
                      settings as those used for cookies.
                      <br />
                      <strong>Web Beacons. </strong>Certain sections of our
                      Service and our emails may contain small electronic files
                      known as web beacons (also referred to as clear gifs,
                      pixel tags, and single-pixel gifs) that permit the
                      Company, for example, to count users who have visited
                      those pages or opened an email and for other related
                      website statistics (for example, recording the popularity
                      of a certain section and verifying system and server
                      integrity).
                    </p>
                  </li>
                </ol>
                <h3>Use of your Personal Information</h3>
                <p>
                  The Company may use your Personal Information for the
                  following purposes:
                </p>
                <ol>
                  <li>
                    <p>
                      <strong>To provide and maintain Our Service: </strong>
                      Including to monitor the usage of Our Service.
                      <br />
                      <strong>
                        To prevent fraud and/or for security related purposes:{" "}
                      </strong>
                      To minimize and prevent fraudulent usage of Our Service
                      and your Personal Information.
                      <br />
                      <strong>To manage your Account: </strong>To manage your
                      registration as a user of the Service. The Personal
                      Information you provide can give you access to different
                      functionalities of the Service that are available to you
                      as a registered user.
                      <br />
                      <strong>For the performance of a contract: </strong>The
                      development, compliance, and undertaking of the purchase
                      contract for the products, items, or services you have
                      purchased or of any other contract with Us through the
                      Service.
                      <br />
                      <strong>To contact you:</strong>Communicating with you via
                      telephone or electronic communication, regarding updates
                      or informative communications related to the
                      functionalities, products or contracted services,
                      including the security updates, when necessary or
                      reasonable for their implementation.
                      <br />
                      <strong>To provide you more information: </strong>News,
                      special offers, and general information about other goods,
                      services, and events which We offer that are similar to
                      those that you have already purchased or enquired about
                      unless you have opted not to receive such information by
                      contacting Us at privacy@elephantcpa.com.
                      <br />
                      <strong>To manage your requests:</strong>: To manage your
                      interactions with Our Service, including processing and
                      intermediating your use of the Website and Service.
                      <br />
                      <strong>For business transfers:</strong> To evaluate or
                      conduct a merger, divestiture, restructuring,
                      reorganization, dissolution, or other sale or transfer of
                      some or all of Our assets, whether as a going concern or
                      as part of bankruptcy, liquidation, or similar proceeding,
                      in which Personal Information held by Us about our Service
                      users is among the assets transferred.
                      <br />
                      <strong>For other purposes:</strong> For other purposes,
                      such as data analysis, identifying usage trends,
                      determining the effectiveness of our promotional
                      campaigns, and to evaluate and improve our Service,
                      products, services, marketing, and your experience.
                    </p>
                  </li>
                </ol>
                <p>
                  We may share your Personal Information in the following
                  situations:
                </p>
                <ol>
                  <li>
                    <p>
                      <strong>With other service providers: </strong>To monitor
                      and analyze the use of our Service, for payment
                      processing, or to contact You.
                      <br />
                      <strong>For business transfers:</strong> In connection
                      with, or during negotiations of, any merger, sale of
                      Company assets, financing, or acquisition of all or a
                      portion of Our business to another company.
                      <br />
                      <strong>With affiliates: </strong>Affiliates include Our
                      parent company and any other subsidiaries, joint venture
                      partners, or other companies that We control or that are
                      under common control with Us.
                      <br />
                      <strong>With business partners:</strong> To offer you
                      certain products, services, or promotions.
                      <br />
                      <strong>With other users:</strong> When you share Personal
                      Information or otherwise interact in the public areas of
                      Our Service with other users, such information may be
                      viewed by other users and may be publicly distributed
                      outside of the Service.
                      <br />
                      <strong>With your consent:</strong> We may disclose your
                      Personal Information for any other purpose with your
                      consent.
                    </p>
                  </li>
                </ol>
                <h3>Retention of Your Personal Information</h3>
                <p>
                  The Company will retain your Personal Information only for as
                  long as is necessary for the purposes set out in this Privacy
                  Policy. We will retain and use your Personal Information to
                  the extent necessary to comply with our legal obligations (for
                  example, if we are required to retain your data to comply with
                  applicable laws), resolve disputes, and enforce our legal
                  agreements and policies.
                </p>
                <p>
                  The Company will also retain Usage Data for internal analysis
                  purposes. Usage Data is generally retained for a shorter
                  period of time, except when this data is used to strengthen
                  the security or to improve the functionality of Our Service,
                  or We are legally obligated to retain this data for longer
                  time periods.
                </p>
                <h3>Transfer of Your Personal Information</h3>
                <p>
                  Your information, including Personal Information, is processed
                  at the Company's operating offices and in any other places
                  where the parties involved in the processing are located. It
                  means that this information may be transferred to — and
                  maintained on — computers located outside of your state,
                  province, country, or other governmental jurisdiction where
                  the data protection laws may differ from those in your
                  jurisdiction.
                </p>
                <p>
                  Your use of the Website and/or Service means you consent to
                  all the terms in this Privacy Policy, including the transfer
                  or disclosure of your Personal Information consistent with
                  this Privacy Policy outside of the jurisdiction you reside in,
                  or access our Website or Service from.
                </p>
                <p>
                  The Company will take all steps reasonably necessary to ensure
                  that your data is treated securely and in accordance with this
                  Privacy Policy.
                </p>
                <h3>Disclosure of Your Personal Information</h3>
                <p>
                  <strong>Business Transactions</strong>
                  <br />
                  If the Company is involved in a merger, acquisition, or asset
                  sale, your Personal Information may be transferred. We may
                  provide notice before your Personal Information is transferred
                  and becomes subject to a different Privacy Policy.
                </p>
                <p>
                  <strong>Law Enforcement</strong>
                  <br />
                  Under certain circumstances, the Company may be required to
                  disclose your Personal Information if required to do so by law
                  or in response to valid requests by public authorities (e.g.,
                  a court or a government agency).
                </p>
                <p>
                  <strong>Other Legal Requirements</strong>
                  <br />
                  The Company may disclose your Personal Information in the good
                  faith belief that such action is necessary to:
                </p>
                <ul>
                  <li>
                    <p>Comply with a legal obligation</p>
                  </li>
                  <li>
                    <p>
                      Protect and defend the rights or property of the Company
                    </p>
                  </li>
                  <li>
                    <p>
                      Prevent or investigate possible wrongdoing in connection
                      with the Website or Service
                    </p>
                  </li>
                  <li>
                    <p>
                      Protect the personal safety of Users of the Website or
                      Service or the public
                    </p>
                  </li>
                  <li>
                    <p>Protect against legal liability</p>
                  </li>
                </ul>
                <h3>Security of your Personal Information</h3>
                <p>
                  The security of your Personal Information is important to Us,
                  but remember that no method of transmission over the Internet,
                  or method of electronic storage is 100% secure. While We
                  strive to use commercially acceptable means to protect your
                  Personal Information, We cannot guarantee its absolute
                  security.
                </p>
                <h2>General Data Protection Regulation (GDPR) Privacy</h2>
                <p>
                  The European Union’s GDPR applies to European Union (EU)
                  citizens and residents including users of this Website or
                  Service. The section below addresses GDPR Privacy.
                </p>
                <h3>
                  Legal Basis for Processing Personal Information under GDPR
                </h3>
                <p>
                  We may process Personal Information under the following
                  conditions:
                </p>
                <ol>
                  <li>
                    <p>
                      <strong>Consent: </strong>You have given your consent for
                      processing Personal Information for one or more specific
                      purposes.
                      <br />
                      <strong>Performance of a contract :</strong> Provision of
                      Personal Information is necessary for the performance of
                      an agreement with you and/or for any pre-contractual
                      obligations thereof.
                      <br />
                      <strong>Legal obligations: </strong>Processing Personal
                      Information is necessary for compliance with a legal
                      obligation to which the Company is subject.
                      <br />
                      <strong>Vital interests: </strong>Processing Personal
                      Information is necessary in order to protect your vital
                      interests or those of another natural person.
                      <br />
                      <strong>Public interests: </strong>Processing Personal
                      Information is related to a task that is carried out in
                      the public interest or in the exercise of official
                      authority vested in the Company.
                      <br />
                      <strong>Legitimate interests: </strong>Processing Personal
                      Information is necessary for the purposes of the
                      legitimate interests pursued by the Company.
                    </p>
                  </li>
                </ol>
                <p>
                  In any case, the Company will gladly help to clarify the
                  specific legal basis that applies to the processing, and in
                  particular whether the provision of Personal Information is a
                  statutory or contractual requirement, or a requirement
                  necessary to enter into a contract.
                </p>
                <h3>Your Rights under the GDPR</h3>
                <p>
                  The Company undertakes to respect the confidentiality of your
                  Personal Information and to guarantee you can exercise your
                  rights.
                </p>
                <p>
                  You have the right under this Privacy Policy, and by law if
                  you are within the EU, to:
                </p>
                <ol>
                  <li>
                    <p>
                      <strong>
                        Request access to your Personal Information.{" "}
                      </strong>
                      The right to access, update, or delete the information We
                      have on you. Whenever made possible, you can access,
                      update, or request deletion of your Personal Information
                      directly within your account settings section. If you are
                      unable to perform these actions yourself, please contact
                      Us to assist You. This also enables you to receive a copy
                      of the Personal Information We hold about you.
                      <br />
                      <strong>
                        Request correction of the Personal Information that We
                        hold about you.{" "}
                      </strong>
                      You have the right to have any incomplete or inaccurate
                      information We hold about you corrected.
                      <br />
                      <strong>
                        Object to processing of your Personal Information.{" "}
                      </strong>
                      This right exists where We are relying on a legitimate
                      interest as the legal basis for Our processing and there
                      is something about your particular situation, which makes
                      you want to object to our processing of your Personal
                      Information on this ground. You also have the right to
                      object where We are processing your Personal Information
                      for direct marketing purposes.
                      <br />
                      <strong>
                        Request erasure of your Personal Information.{" "}
                      </strong>
                      You have the right to ask Us to delete or remove Personal
                      Information when there is no good reason for Us to
                      continue processing it.
                      <br />
                      <strong>
                        Request the transfer of your Personal Information.{" "}
                      </strong>
                      We will provide to you, or to a third-party you have
                      chosen, your Personal Information in a structured,
                      commonly used, machine-readable format. Please note that
                      this right only applies to automated information which you
                      initially provided consent for Us to use or where We used
                      the information to perform a contract with you.
                      <br />
                      <strong>Withdraw your consent. </strong>
                      You have the right to withdraw your consent on using your
                      Personal Information. If you withdraw your consent, We may
                      not be able to provide you with access to certain specific
                      functionalities of the Service.
                    </p>
                  </li>
                </ol>
                <h3>Exercising of Your GDPR Data Protection Rights</h3>
                <p>
                  You may exercise your rights of access, rectification,
                  cancellation, and opposition by contacting Us. Please note
                  that we may ask you to verify your identity before responding
                  to such requests. If you make a request, We will try our best
                  to respond to you as soon as possible.
                </p>
                <p>
                  You have the right to complain to a Data Protection Authority
                  about Our collection and use of your Personal Information. For
                  more information, if you are in the European Economic Area
                  (EEA), please contact your local data protection authority in
                  the EEA.
                </p>
                <h2>CCPA Privacy</h2>
                <p>
                  This privacy notice section for California residents
                  supplements the information contained in Our Privacy Policy
                  and it applies solely to all visitors, users, and others who
                  reside in the State of California.
                </p>
                <h3>Categories of Personal Information Collected</h3>
                <p>
                  We collect information that identifies, relates to, describes,
                  references, is capable of being associated with, or could
                  reasonably be linked, directly or indirectly, with a
                  particular Consumer or Device. The following is a list of
                  categories of Personal Information which we may collect or may
                  have been collected from California residents within the last
                  twelve (12) months.
                </p>
                <p>
                  Please note that the categories and examples provided in the
                  list below are those defined in the CCPA. This does not mean
                  that all examples of that category of Personal Information
                  were in fact collected by Us, but reflects our good faith
                  belief to the best of our knowledge that some of that
                  information from the applicable category may be and may have
                  been collected. For example, certain categories of Personal
                  Information would only be collected if you provided such
                  Personal Information directly to Us.
                </p>
                <ol>
                  <li>
                    <p>
                      <strong>Category A: Identifiers.</strong>
                      <br />
                      Examples: A real name, alias, postal address, unique
                      personal identifier, online identifier, Internet Protocol
                      address, email address, account name, driver's license
                      number, passport number, or other similar identifiers.
                      <br />
                      Collected: Yes.
                      <br />
                      <strong>
                        Category B: Personal information categories listed in
                        the California Customer Records statute (Cal. Civ. Code
                        § 1798.80(e)).
                      </strong>
                      <br />
                      Examples: A name, signature, Social Security number,
                      physical characteristics or description, address,
                      telephone number, passport number, driver's license or
                      state identification card number, insurance policy number,
                      education, employment, employment history, bank account
                      number, credit card number, debit card number, or any
                      other financial information, medical information, or
                      health insurance information. Some Personal Information
                      included in this category may overlap with other
                      categories.
                      <br />
                      Collected: Yes.
                      <br />
                      <strong>
                        Category C: Protected classification characteristics
                        under California or federal law.
                      </strong>
                      <br />
                      Examples: Age (40 years or older), race, color, ancestry,
                      national origin, citizenship, religion or creed, marital
                      status, medical condition, physical or mental disability,
                      sex (including gender, gender identity, gender expression,
                      pregnancy or childbirth and related medical conditions),
                      sexual orientation, veteran or military status, genetic
                      information (including familial genetic information).
                      <br />
                      Collected: No.
                      <br />
                      <strong>Category D: Commercial information.</strong>
                      <br />
                      Examples: Records and history of products or services
                      purchased or considered.
                      <br />
                      Collected: Yes.
                      <br />
                      <strong>Category E: Biometric information.</strong>
                      <br />
                      Examples: Genetic, physiological, behavioral, and
                      biological characteristics, or activity patterns used to
                      extract a template or other identifier or identifying
                      information, such as, fingerprints, faceprints, and
                      voiceprints, iris or retina scans, keystroke, gait, or
                      other physical patterns, and sleep, health, or exercise
                      data.
                      <br />
                      Collected: No.
                      <br />
                      <strong>
                        Category F: Internet or other similar network activity.
                      </strong>
                      <br />
                      Examples: Interaction with our Service or advertisement.
                      <br />
                      Collected: Yes.
                      <br />
                      <strong>Category G: Geolocation data.</strong>
                      <br />
                      Examples: Approximate physical location.
                      <br />
                      Collected: No.
                      <br />
                      <strong>Category H: Sensory data.</strong>
                      <br />
                      Examples: Audio, electronic, visual, thermal, olfactory,
                      or similar information.
                      <br />
                      Collected: No.
                      <br />
                      <strong>
                        Category I: Professional or employment-related
                        information.
                      </strong>
                      <br />
                      Examples: Current or past job history or performance
                      evaluations.
                      <br />
                      Collected: No.
                      <br />
                      <strong>
                        Category J: Non-public education information (per the
                        Family Educational Rights and Privacy Act (20 U.S.C.
                        Section 1232g, 34 C.F.R. Part 99)).
                      </strong>
                      <br />
                      Examples: Education records directly related to a student
                      maintained by an educational institution or party acting
                      on its behalf, such as grades, transcripts, class lists,
                      student schedules, student identification codes, student
                      financial information, or student disciplinary records.
                      <br />
                      Collected: No.
                      <br />
                      <strong>
                        Category K: Inferences drawn from other Personal
                        Information.
                      </strong>
                      <br />
                      Examples: Profile reflecting a person's preferences,
                      characteristics, psychological trends, predispositions,
                      behavior, attitudes, intelligence, abilities, and
                      aptitudes.
                      <br />
                      Collected: No.
                    </p>
                  </li>
                </ol>
                <p>Under the CCPA, Personal Information does not include:</p>
                <ul>
                  <li>
                    <p>
                      Publicly available information from government records
                    </p>
                  </li>
                  <li>
                    <p>Deidentified or aggregated consumer information</p>
                  </li>
                  <li>
                    <p>Information excluded from the CCPA's scope, such as:</p>
                  </li>
                  <ul>
                    <li>
                      <p>
                        Health or medical information covered by the Health
                        Insurance Portability and Accountability Act of 1996
                        (HIPAA) and the California Confidentiality of Medical
                        Information Act (CMIA) or clinical trial data
                      </p>
                    </li>
                    <li>
                      <p>
                        Personal Information covered by certain sector-specific
                        privacy laws, including the Fair Credit Reporting Act
                        (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California
                        Financial Information Privacy Act (FIPA), and the
                        Driver's Privacy Protection Act of 1994
                      </p>
                    </li>
                  </ul>
                </ul>
                <h3>Sources of Personal Information</h3>
                <p>
                  We obtain the categories of Personal Information listed above
                  from the following categories of sources:
                </p>
                <ul>
                  <li>
                    <p>
                      <strong>Directly from you.</strong> For example, from the
                      forms you complete on our Service, preferences you express
                      or provide through our Service, or from your purchases on
                      our Service.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Indirectly from you. </strong>For example, from
                      observing your activity on our Service.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Automatically from you. </strong>For example,
                      through cookies We or our Service Providers set on your
                      Device as you navigate through our Service.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>From service providers. </strong>For example,
                      third-party vendors to monitor and analyze the use of our
                      Service, third-party vendors for payment processing, or
                      other third-party vendors that We use to provide the
                      Service to you.
                    </p>
                  </li>
                </ul>
                <h3>
                  Use of Personal Information for Business Purposes or
                  Commercial Purposes
                </h3>
                <p>
                  We may use or disclose Personal Information We collect for
                  "business purposes" or "commercial purposes" (as defined under
                  the CCPA), which may include the following examples:
                </p>
                <ul>
                  <li>
                    <p>
                      To operate our Service and provide you with our Service.
                    </p>
                  </li>
                  <li>
                    <p>
                      To provide you with support and to respond to your
                      inquiries, including to investigate and address your
                      concerns and monitor and improve our Service.
                    </p>
                  </li>
                  <li>
                    <p>
                      To fulfill or meet the reason you provided the
                      information. For example, if you share your contact
                      information to ask a question about our Service, We will
                      use that Personal Information to respond to your inquiry.
                      If you provide your Personal Information to purchase a
                      product or service, We will use that information to
                      process your payment and facilitate delivery.
                    </p>
                  </li>
                  <li>
                    <p>
                      To respond to law enforcement requests and as required by
                      applicable law, court order, or governmental regulations.
                    </p>
                  </li>
                  <li>
                    <p>
                      As described to you when collecting your Personal
                      Information or as otherwise set forth in the CCPA.
                    </p>
                  </li>
                  <li>
                    <p> For internal administrative and auditing purposes.</p>
                  </li>
                  <li>
                    <p>
                      To detect security incidents and protect against
                      malicious, deceptive, fraudulent or illegal activity,
                      including, when necessary, to prosecute those responsible
                      for such activities.
                    </p>
                  </li>
                </ul>
                <p>
                  Please note that the examples provided above are illustrative
                  and not intended to be exhaustive.
                </p>
                <p>
                  If We decide to collect additional categories of Personal
                  Information or use the Personal Information We collected for
                  materially different, unrelated, or incompatible purposes We
                  will update this Privacy Policy.
                </p>
                <h3>
                  Disclosure of Personal Information for Business Purposes or
                  Commercial Purposes
                </h3>
                <p>
                  We may use or disclose, and may have used or disclosed in the
                  last twelve (12) months, the following categories of Personal
                  Information for business or commercial purposes:
                </p>
                <ul>
                  <li>
                    <p>Category A: Identifiers</p>
                  </li>
                  <li>
                    <p>
                      Category B: Personal information categories listed in the
                      California Customer Records statute (Cal. Civ. Code §
                      1798.80(e))
                    </p>
                  </li>
                  <li>
                    <p>Category D: Commercial information</p>
                  </li>
                  <li>
                    <p>
                      Category F: Internet or other similar network activity
                    </p>
                  </li>
                </ul>
                <p>
                  Please note that the categories listed above are those defined
                  in the CCPA. This does not mean that all examples of that
                  category of Personal Information were in fact disclosed, but
                  reflects our good faith belief to the best of our knowledge
                  that some of that information from the applicable category may
                  be and may have been disclosed.
                </p>
                <p>
                  When We disclose Personal Information for a business purpose
                  or a commercial purpose, We enter a contract that describes
                  the purpose and requires the recipient to both keep that
                  Personal Information confidential and not use it for any
                  purpose except performing the contract.
                </p>
                <h3>Sharing of Personal Information</h3>
                <p>
                  We may share your Personal Information identified in the above
                  categories with the following categories of third parties:
                </p>
                <ul>
                  <li>
                    <p> Service providers</p>
                  </li>
                  <li>
                    <p> Payment processors</p>
                  </li>
                  <li>
                    <p> Our affiliates</p>
                  </li>
                  <li>
                    <p> Our business partners</p>
                  </li>
                  <li>
                    <p>
                      Third-party vendors to whom you or your agents authorize
                      Us to disclose your Personal Information in connection
                      with products or services We provide to You
                    </p>
                  </li>
                </ul>
                <h3>
                  Sale of Personal Information of Minors Under 16 Years of Age
                </h3>
                <p>
                  We do not sell the Personal Information of Consumers We
                  actually know are less than 16 years of age, unless We receive
                  affirmative authorization (the "right to opt-in") from either
                  the Consumer who is between 13 and 16 years of age, or the
                  parent or guardian of a Consumer less than 13 years of age.
                  Consumers who opt-in to the sale of Personal Information may
                  opt-out of future sales at any time. To exercise the right to
                  opt-out, you (or your authorized representative) may submit a
                  request to Us by contacting Us.
                </p>
                <p>
                  If you have reason to believe that a child under the age of 13
                  (or 16) has provided Us with Personal Information, please
                  contact Us with sufficient detail to enable Us to delete that
                  information.
                </p>
                <h3>Your Rights under the CCPA</h3>
                <p>
                  The CCPA provides California residents with specific rights
                  regarding their Personal Information. If you are a resident of
                  California, you have the following rights:
                </p>
                <ol>
                  <li>
                    <p>
                      <strong>The right to notice. </strong>You have the right
                      to be notified which categories of Personal Information
                      are being collected and the purposes for which the
                      Personal Information is being used.
                    </p>
                    <p>
                      <strong>The right to request. </strong>Under CCPA, you
                      have the right to request that We disclose information to
                      you about Our collection, use, sale, disclosure for
                      business purposes, and share of Personal Information. Once
                      We receive and confirm your request, We will disclose to
                      you:
                    </p>
                    <ul>
                      <li>
                        <p>
                          The categories of Personal Information We collected
                          about you
                        </p>
                      </li>
                      <li>
                        <p>
                          The categories of sources for the Personal Information
                          We collected about you
                        </p>
                      </li>
                      <li>
                        <p>
                          Our business or commercial purpose for collecting or
                          selling that Personal Information
                        </p>
                      </li>
                      <li>
                        <p>
                          The categories of third parties with whom We share
                          that Personal Information
                        </p>
                      </li>
                      <li>
                        <p>
                          The specific pieces of Personal Information We
                          collected about you
                        </p>
                      </li>
                      <li>
                        <p>
                          If we sold your Personal Information or disclosed your
                          Personal Information for a business purpose, We will
                          disclose to you i) the categories of Personal
                          Information categories sold, and ii) the categories of
                          Personal Information categories disclosed.
                        </p>
                      </li>
                    </ul>
                    <p>
                      <strong>
                        The right to say no to the sale of Personal Information
                        (opt-out).{" "}
                      </strong>
                      You have the right to direct Us to not sell your Personal
                      Information. To submit an opt-out request please contact
                      Us at privacy@elephantcpa.com.
                    </p>
                    <p>
                      <strong>
                        The right to delete Personal Information.{" "}
                      </strong>
                      You have the right to request the deletion of your
                      Personal Information, subject to certain exceptions. Once
                      We receive and confirm your request, We will delete (and
                      direct Our Service Providers to delete) your Personal
                      Information from our records, unless an exception applies.
                      We may deny your deletion request if retaining the
                      information is necessary for Us or Our Service Providers
                      to:
                    </p>
                  </li>
                  <ul>
                    <li>
                      <p>
                        Complete the transaction for which We collected the
                        Personal Information, provide a good or service that you
                        requested, take actions reasonably anticipated within
                        the context of our ongoing business relationship with
                        you, or otherwise perform our contract with you.
                      </p>
                    </li>
                    <li>
                      <p>
                        Detect security incidents, protect against malicious,
                        deceptive, fraudulent, or illegal activity, or prosecute
                        those responsible for such activities.
                      </p>
                    </li>
                    <li>
                      <p>
                        Debug products to identify and repair errors that impair
                        existing intended functionality.
                      </p>
                    </li>
                    <li>
                      <p>
                        Exercise free speech, ensure the right of another
                        consumer to exercise their free speech rights, or
                        exercise another right provided for by law.
                      </p>
                    </li>
                    <li>
                      <p>
                        Comply with the California Electronic Communications
                        Privacy Act (Cal. Penal Code § 1546 et. seq.).
                      </p>
                    </li>
                    <li>
                      <p>
                        Engage in public or peer-reviewed scientific,
                        historical, or statistical research in the public
                        interest that adheres to all other applicable ethics and
                        privacy laws, when the information's deletion may likely
                        render impossible or seriously impair the research's
                        achievement, if you previously provided informed
                        consent.
                      </p>
                    </li>
                    <li>
                      <p>
                        Enable solely internal uses that are reasonably aligned
                        with consumer expectations based on your relationship
                        with Us.
                      </p>
                    </li>
                    <li>
                      <p>• Comply with a legal obligation.</p>
                    </li>
                    <li>
                      <p>
                        Make other internal and lawful uses of that information
                        that are compatible with the context in which you
                        provided it.
                      </p>
                    </li>
                  </ul>
                  <p>
                    <strong>The right not to be discriminated against. </strong>
                    You have the right not to be discriminated against for
                    exercising any of your consumer's rights, including by:
                  </p>
                  <ul>
                    <li>
                      <p> Denying goods or services to you.</p>
                    </li>
                    <li>
                      <p>
                        Charging different prices or rates for goods or
                        services, including the use of discounts or other
                        benefits or imposing penalties.
                      </p>
                    </li>
                    <li>
                      <p>
                        Providing a different level or quality of goods or
                        services to you.
                      </p>
                    </li>
                    <li>
                      <p>
                        Suggesting that you will receive a different price or
                        rate for goods or services or a different level or
                        quality of goods or services.
                      </p>
                    </li>
                  </ul>
                </ol>
                <h3>Exercising your CCPA Data Protection Rights</h3>
                <p>
                  In order to exercise any of your rights under the CCPA, and if
                  you are a California resident, you can contact Us:
                </p>
                <ol>
                  <li>
                    <p>By email: privacy@elephantcpa.com</p>
                    <p>
                      By visiting this page on our website:
                      http://www.elephantcpa.com/contact
                    </p>
                  </li>
                </ol>
                <p>
                  Only you, or a person registered with the California Secretary
                  of State that you authorize to act on your behalf, may make a
                  verifiable request related to your Personal Information.
                </p>
                <h3>Do Not Sell My Personal Information</h3>
                <p>
                  You have the right to opt-out of the sale of your Personal
                  Information. Once We receive and confirm a verifiable consumer
                  request from you, we will stop selling your Personal
                  Information. To exercise your right to opt-out, please contact
                  Us.
                </p>
                <p>
                  The Service Providers we partner with (for example, our
                  analytics or advertising partners) may use technology on the
                  Service that sells Personal Information as defined by the CCPA
                  law. If you wish to opt out of the use of your Personal
                  Information for interest-based advertising purposes and these
                  potential sales as defined under CCPA law, you may do so by
                  following the instructions below.
                </p>
                <p>
                  Please note that any opt out is specific to the browser you
                  use. You may need to opt out on every browser that you use.
                </p>
                <p>
                  <strong>Website</strong>
                  <br />
                  You can opt out of receiving ads that are personalized as
                  served by our Service Providers by following our instructions
                  presented on the Service:
                </p>
                <ul>
                  <li>
                    <p>
                      The NAI's opt-out platform:
                      http://www.networkadvertising.org/choices/
                    </p>
                  </li>
                  <li>
                    <p>
                      The EDAA's opt-out platform
                      http://www.youronlinechoices.com/
                    </p>
                  </li>
                  <li>
                    <p>
                      The DAA's opt-out platform:
                      http://optout.aboutads.info/?c=2&lang=EN
                    </p>
                  </li>
                </ul>
                <p>
                  The opt out will place a cookie on your computer that is
                  unique to the browser you use to opt out. If you change
                  browsers or delete the cookies saved by your browser, you will
                  need to opt out again.
                </p>
                <p>
                  <strong>Mobile Devices</strong>
                  <br />
                  Your mobile device may give you the ability to opt out of the
                  use of information about the apps you use in order to serve
                  you ads that are targeted to your interests:
                </p>
                <ul>
                  <li>
                    <p>
                      "Opt out of Interest-Based Ads" or "Opt out of Ads
                      Personalization" on Android devices
                    </p>
                  </li>
                  <li>
                    <p>"Limit Ad Tracking" on iOS devices</p>
                  </li>
                </ul>
                <p>
                  You can also stop the collection of location information from
                  your mobile device by changing the preferences on your mobile
                  device.
                </p>
                <h2>
                  "Do Not Track" Policy as Required by California Online Privacy
                  Protection Act (CalOPPA)
                </h2>
                <p>Our Service does not respond to Do Not Track signals.</p>
                <p>
                  However, some third-party websites do keep track of your
                  browsing activities. If you are visiting such websites, you
                  can set your preferences in your web browser to inform
                  websites that you do not want to be tracked. You can enable or
                  disable DNT by visiting the preferences or settings page of
                  your web browser.
                </p>
                <h2>
                  Your California Privacy Rights (California's Shine the Light
                  law)
                </h2>
                <p>
                  Under California Civil Code Section 1798 (California's Shine
                  the Light law), California residents with an established
                  business relationship with Us can request information once per
                  year about sharing their Personal Information with third
                  parties for the third parties' direct marketing purposes.
                </p>
                <p>
                  If you would like to request more information under the
                  California Shine the Light law, and if you are a California
                  resident, you can contact Us using the contact information
                  provided below.
                </p>
                <h2>Links to Other Websites</h2>
                <p>
                  Our Service may contain links to other websites that are not
                  operated by Us. If you click on a third-party link, you will
                  be directed to that third party's site. We strongly advise you
                  to review the Privacy Policy of every site you visit.
                </p>
                <p>
                  We have no control over and assume no responsibility for the
                  content, privacy policies, or practices of any third-party
                  sites or services.
                </p>
                <h2>Changes To This Privacy Policy</h2>
                <p>
                  We may update Our Privacy Policy from time to time. We will
                  notify you of any changes by posting the new Privacy Policy on
                  this page.
                </p>
                <p>
                  We may update Our Privacy Policy from time to time. We will
                  notify you of any changes by posting the new Privacy Policy on
                  this page.
                </p>
                <p>
                  You are advised to review this Privacy Policy periodically for
                  any changes. Changes to this Privacy Policy are effective when
                  they are posted on this page.
                </p>
                <h2>Contact Us</h2>
                <p>
                  If you have any questions about this Privacy Policy, you can
                  contact Us:
                </p>
                <ol>
                  <li>
                    <p>By email: privacy@elephantcpa.com</p>
                    <p>
                      By visiting this page on Our website:
                      http://www.elephantcpa.com/contact
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </main>
        <LandingPageFooter />
      </Layout>
    </>
  )
}

export default PrivacyPolice
